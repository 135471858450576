import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import people from "../../data/people.yaml"

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'


const AboutManagementPage = ({data}) => {
  return (
    <Layout>
      <div className="about management">

      <Helmet>
        <title>DigiWells – Management</title>
        <meta property="og:title" content="DigiWells – Management" />
        <meta name="description" content="DigiWells management." />
        <meta property="og:description" content="DigiWells management." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">Management</h1>

            <div className="page-intro">
               The centre management team will be composed by a centre director, a centre director assistant and an administrative coordinator. The centre manager will oversee the day-to-day management of the centre, including communication with the board and the work pagage leaders.
            </div>

            <div className="contact">
              {
                people.management &&
                people.management.map(function(managementGroup) {
                  return (
                    <>
                      <h2>{managementGroup.groupName}</h2>
                      <div className="people">
                        {
                          managementGroup.people &&
                          managementGroup.people.map(function(person) {

                            const photo = require('../../assets/images/' + person.photo);
                            return (

                              <div className="person-card">
                                <img className="person-image" alt="" src={photo}/>
                                <div className="person-text">
                                  <p className="person-name">
                                    <a href={person.url}>{person.name}
                                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -2 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                  </p>
                                  <p>{person.workTitle}</p>
                                  <p>{person.email}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutManagementPage
